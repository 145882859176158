<template>
  <div>
    <h1>
      {{ $t("format.description") }}
      <help>
        {{ $t("format.info") }}
      </help>
    </h1>
    <calendarFormat />
  </div>
</template>
<script>
export default {
  components: {
    calendarFormat() {
      return import("@/components/business/format");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped></style>
